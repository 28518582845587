import {createRouter, createWebHistory} from 'vue-router';
import routes from './routes/index';
import store from "@/store";
import middlewarePipeline from "@/router/middlewarePipeline";


const router = createRouter({
    mode: "history",
    history: createWebHistory(),
    routes,
    // scrollBehavior() {
    //     return { left: 0, top: 0 };
    // }
});


router.beforeEach((to, from, next ) => {
    const middleware = to.meta.middleware;
    const context = { to, from, next, store };

    if (!middleware) {
        return next();
    }

    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    });

});

export default router;