import ModelView from "../../views/ModelView";
import {AUTH_HELPER} from "../../utils/helpers";

export default [
    {
        path: '/reporting',
        name: 'Reporting',
        redirect: '/reporting/',
        meta: {
            requirePermission: true,
            model: AUTH_HELPER.authModels.Reporting
        },
        component: ModelView,
        children: [
            {
                path: '',
                meta: {
                    action: AUTH_HELPER.authActions.browse,
                    breadcrumb: [
                        {
                            label: 'Reporting',
                        }
                    ]
                },
                component: () => import('../../views/Reporting/Index')
            },
            {
                path: '/reporting/calls',
                name: 'Calls',
                redirect: '/reporting/calls/',
                component: ModelView,
                children: [
                    {
                        path: '',
                        meta: {
                            action: AUTH_HELPER.authActions.browse,
                            breadcrumb: [
                                {
                                    parent: 'Reporting',
                                    label: 'Calls',
                                }
                            ]
                        },
                        component: () => import('../../views/Reporting/Calls/Index'),
                    },
                    {
                        path: "/reporting/calls/:CallId",
                        name: "Call",
                        meta: {
                            action: AUTH_HELPER.authActions.add,
                            breadcrumb: [
                                {
                                    parent: 'Calls',
                                    label: 'Call',
                                }
                            ]
                        },
                        component: () => import('../../views/Reporting/Calls/Call'),
                    }
                ]
            },
            {
                path: '/reporting/roles',
                name: 'Roles',
                redirect: '/reporting/roles/',
                component: ModelView,
                children: [
                    {
                        path: '',
                        meta: {
                            action: AUTH_HELPER.authActions.browse,
                            breadcrumb: [
                                {
                                    label: 'Roles',
                                }
                            ]
                        },
                        component: () => import('../../views/Reporting/Roles/Index'),
                    },
                    {
                        path: "/reporting/roles/breakdown",
                        name: "Role",
                        meta: {
                            action: AUTH_HELPER.authActions.add,
                            breadcrumb: [
                                {
                                    parent: 'Roles',
                                    label: 'Role',
                                }
                            ]
                        },
                        component: () => import('../../views/Reporting/Roles/Role'),
                    }
                ]
            },
            {
                path: '/reporting/instances',
                name: 'Instances',
                redirect: '/reporting/instances/',
                component: ModelView,
                children: [
                    {
                        path: '',
                        meta: {
                            action: AUTH_HELPER.authActions.browse,
                            breadcrumb: [
                                {
                                    label: 'Instances',
                                }
                            ]
                        },
                        component: () => import('../../views/Reporting/Instances/Index'),
                    },
                    {
                        path: "/reporting/instances/breakdown",
                        name: "Instance Breakdown",
                        meta: {
                            action: AUTH_HELPER.authActions.add,
                            breadcrumb: [
                                {
                                    parent: 'Instances',
                                    label: 'Instance Breakdown',
                                }
                            ]
                        },
                        component: () => import('../../views/Reporting/Instances/Instance'),
                    }
                ]
            }
        ]
    },
];