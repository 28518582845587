export default [
    {
        path: '/error',
        name: 'error',
        component: () => import('../../views/Errors/Error')
    },
    {
        path: '/error/404',
        name: 'NotFound',
        component: () => import('../../views/Errors/NotFound')
    },
    {
        path: '/error/forbidden',
        name: 'Forbidden',
        component: () => import('../../views/Errors/Forbidden')
    },
    {
        path: '/error/access',
        name: 'Access',
        component: () => import('../../views/Errors/Access')
    }
];