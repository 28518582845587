import guest from "../../middleware/guest";

export default [
    {
        path: '/register',
        name: 'register',
        meta: {
          middleware: [ guest ]
        },
        component: () => import('../../views/Auth/Register')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
          middleware: [ guest ]
        },
        component: () => import('../../views/Auth/Login')
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
          middleware: [ guest ]
        },
        component: () => import('../../views/Auth/Logout')
    },
    {
        path: '/reset-password',
        name: 'resetPassword',
        meta: {
          middleware: [ guest ]
        },
        component: () => import('../../views/Auth/ResetPassword')
    },
    {
        path: '/reset-password/thanks',
        name: 'resetPasswordThankYou',
        meta: {
          middleware: [ guest ]
        },
        component: () => import('../../views/Auth/ResetPasswordThankYou')
    },
    {
        path: '/forgot-password',
        name: 'forgotPassword',
        meta: {
          middleware: [ guest ]
        },
        component: () => import('../../views/Auth/ForgotPassword')
    },
    {
        path: '/email-verification',
        name: 'emailVerification',
        meta: {
            middleware: [ guest ]
        },
        component: () => import('../../views/Auth/EmailVerificationNotice')
    },
    {
        path: '/email-verification-request',
        name: 'emailVerificationRequest',
        meta: {
            middleware: [ guest ]
        },
        component: () => import('../../views/Auth/EmailVerificationRequest')
    },
    {
        path: '/email-verification-confirmation',
        name: 'emailVerificationConfirmation',
        meta: {
            middleware: [ guest ]
        },
        component: () => import('../../views/Auth/EmailVerificationConfirmation')
    },
    {
        path: '/email-verification-expired',
        name: 'emailVerificationExpired',
        meta: {
            middleware: [ guest ]
        },
        component: () => import('../../views/Auth/EmailVerificationExpired')
    }
];