import * as API from "@/services/API";

const baseUrl = '/v1/user';

export default {
    changePassword(payload) {
        return API.apiClient.post(`${baseUrl}/change-password`, payload);
    },
    changeEmail(payload) {
        return API.apiClient.post(`${baseUrl}/change-email`, payload);
    },
    accountDetails() {
        return API.apiClient.get(`${baseUrl}/settings`);
    },

    updateAccountDetails(payload) {
        return API.apiClient.post(`${baseUrl}/settings`, payload);
    },
}