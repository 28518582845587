import Vuex from 'vuex';

import * as auth from "@/store/modules/Auth";
import * as user from "@/store/modules/User";
import * as role from "@/store/modules/Role";
import * as Instance from "@/store/modules/Instance";
import * as Action from "@/store/modules/Action";
import * as Account from "@/store/modules/Account";
import * as Calls from "@/store/modules/Calls";
import * as Reporting from "@/store/modules/Reporting";
import * as AccountSettings from "@/store/modules/AccountSettings";


export default new Vuex.Store({
    strict: true,

    modules: {
        auth,
        user,
        role,
        Instance,
        Action,
        Account,
        Calls,
        Reporting,
        AccountSettings
    }
});