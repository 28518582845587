import ModelView from "../../views/ModelView";
import {AUTH_HELPER} from "../../utils/helpers";

export default [
    {
        path: '/users',
        name: 'users',
        redirect: '/users/',
        meta: {
            requirePermission: true,
            model: AUTH_HELPER.authModels.User
        },
        component: ModelView,
        children: [
            {
                path: '',
                meta: {
                    action: AUTH_HELPER.authActions.browse,
                    breadcrumb: [
                        {
                            label: 'users',
                        }
                    ]
                },
                component: () => import('../../views/Users/Index')
            },
            {
                path: "new",
                name: "newUser",
                meta: {
                    action: AUTH_HELPER.authActions.add,
                    breadcrumb: [
                        {
                            parent: 'users',
                            label: 'new',
                        }
                    ]
                },
                component: () => import('../../views/Users/UserNew'),
            },
            {
                path: "verify-email",
                name: "verifyEmail",
                meta: {
                    action: AUTH_HELPER.authActions.read,
                    breadcrumb: [
                        {
                            parent: 'users',
                            label: 'verify-email',
                        }
                    ]
                },
                component: () => import('../../views/Users/VerifyEmail'),
            },
            {
                path: "settings",
                name: "userSettings",
                meta: {
                    action: AUTH_HELPER.authActions.read,
                    breadcrumb: [
                        {
                            parent: 'users',
                            label: 'settings',
                        }
                    ]
                },
                component: () => import('../../views/Users/UserProfile'),
            },
            {
                path: ':userId',
                name: 'user',
                meta: {
                    action: AUTH_HELPER.authActions.read,
                    breadcrumb: [
                        {
                            parent: 'users',
                            label: 'show',
                        }
                    ]
                },
                component: () => import('../../views/Users/User')
            },
            {
                path: ":userId/edit",
                name: "editUser",
                component: () => import('../../views/Users/UserEdit'),
                meta: {
                    action: AUTH_HELPER.authActions.edit,
                    breadcrumb: [
                        {
                            parent: 'users',
                            label: 'edit',
                        }
                    ]
                },
            },
        ]
    },
];