import * as API from "@/services/API";

const baseUrl = '/v1/Account'.toLowerCase() + 's/';

export default {
    addRecord(payload) {
        return API.apiClient.post(baseUrl, payload);
    },
    browseRecords(payload) {
        var endpoint = baseUrl;
        if (payload !== undefined) {
            endpoint += '?';
            var params = new URLSearchParams(payload);
            endpoint += params.toString();
        }
        return API.apiClient.get(endpoint);
    },
    readRecord(recordId) {
        return API.apiClient.get(baseUrl + recordId);
    },
    editRecord(payload) {
        return API.apiClient.patch(baseUrl + payload.id, payload);
    },
    deleteRecord(id) {
        return API.apiClient.delete(baseUrl + id);
    },
    paginateRecords(link) {
        return API.apiClient.get(link);
    },
}