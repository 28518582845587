import * as API from "@/services/API";

const baseUrl = '/v1/Instance'.toLowerCase() + 's/';

export default {
    browseRecords(payload) {
        var endpoint = baseUrl;
        if (payload !== undefined) {
            endpoint += '?';
            var params = new URLSearchParams(payload);
            endpoint += params.toString();
        }
        return API.apiClient.get(endpoint);
    },
    readRecord(recordId) {
        return API.apiClient.get(baseUrl + recordId);
    },
    editRecord(payload) {
        return API.apiClient.patch(baseUrl + payload.id, payload);
    },
    addRecord(payload) {
        return API.apiClient.post(baseUrl, payload);
    },
    deleteRecord(id) {
        return API.apiClient.delete(baseUrl + id);
    },
    paginateRecords(link) {
        return API.apiClient.get(link);
    },

    listAvailablePhoneNumberCountries() {
        return API.apiClient.get(baseUrl + 'phonenumbers/countries');
    },


    /*listAvailablePhoneNumbers(payload) {
            var endpoint = baseUrl + 'phonenumbers/' + payload.country;
        if (payload !== undefined) {
            endpoint += '?';
            let data = {
                type: payload.type,
                filter: payload.filter
            };
            if (payload.account_id !== null) {
                data.account_id = payload.account_id;
            }
            var params = new URLSearchParams(data);
            endpoint += params.toString();
        }
        return API.apiClient.get(endpoint);
    },*/
    listAvailablePhoneNumbers(payload) {
        console.log(payload);
        var endpoint = baseUrl + 'phonenumbers/' + payload.country;
        // if (payload !== undefined) {
        //     endpoint += '?';
        //     var params = new URLSearchParams(payload);
        //     endpoint += params.toString();
        // }
        delete payload.country;
        return API.apiClient.get(endpoint, { params: payload});
    },

    purchasePhoneNumber(payload) {
        return API.apiClient.post(baseUrl + payload.instanceId + '/' + payload.phone_number);
    },
}