import ModelView from "../../views/ModelView";
import {AUTH_HELPER} from "../../utils/helpers";

export default [
    {
        path: '/actions',
        name: 'actions',
        redirect: '/actions/',
        meta: {
            requirePermission: true,
            model: AUTH_HELPER.authModels.Action
        },
        component: ModelView,
        children: [
            {
                path: '',
                meta: {
                    action: AUTH_HELPER.authActions.browse,
                    breadcrumb: [
                        {
                            label: 'Actions',
                        }
                    ]
                },
                component: () => import('../../views/Actions/Index')
            },
            {
                path: "/Actions/new",
                name: "newAction",
                meta: {
                    action: AUTH_HELPER.authActions.add,
                    breadcrumb: [
                        {
                            parent: 'Actions',
                            label: 'new',
                        }
                    ]
                },
                component: () => import('../../views/Actions/ActionNew'),
            },
            {
                path: '/Actions/:ActionId',
                name: 'Action',
                meta: {
                    action: AUTH_HELPER.authActions.read,
                    breadcrumb: [
                        {
                            parent: 'Actions',
                            label: 'show',
                        }
                    ]
                },
                component: () => import('../../views/Actions/Action')
            },
            {
                path: "/Actions/:ActionId/edit",
                name: "editAction",
                component: () => import('../../views/Actions/ActionEdit'),
                meta: {
                    action: AUTH_HELPER.authActions.edit,
                    breadcrumb: [
                        {
                            parent: 'Actions',
                            label: 'edit',
                        }
                    ]
                },
            },
        ]
    }
];