import * as API from "@/services/API";

const baseUrl = '/v1/users/';


export default {
    getUser(userId) {
        return API.apiClient.get(baseUrl + userId);
    },

    getUsers(payload) {
        console.log("PAYLOAD");
        console.log(payload);
        var endpoint = baseUrl;
        if (payload !== undefined) {
            endpoint += '?';
            var params = new URLSearchParams(payload);
            endpoint += params.toString();
        }
        return API.apiClient.get(endpoint);
    },

    paginateUsers(link) {
        return API.apiClient.get(link);
    },

    saveUser(data) {
        return API.apiClient.post(baseUrl,data);
    },

    editUser(data) {
        return API.apiClient.patch(baseUrl + data.id, data);
    },

    deleteUser(id) {
        return API.apiClient.delete(baseUrl + id);
    },

    passwordResetEmail(id){
        return API.apiClient.get(`${baseUrl}${id}/password-reset-email`);
    },
    emailVerification(id){
        return API.apiClient.get(`${baseUrl}${id}/resend-email-verification`);
    },
    getAvailableUserRoles(){
        return API.apiClient.get(`/v1/user/roles`);
    },
    getAssignedUserRoles(id){
        return API.apiClient.get(`${baseUrl}${id}/roles`);
    },
    updateUserRoles(data){
        return API.apiClient.patch(`${baseUrl}${data.id}/roles`, data);
    },
}