import router from "@/router";

export default function auth({ to, next, store }) {
    const loginQuery = {
        path: "/login",
        query: { redirect: to.fullPath }
    };

    const emailVerificationQuery = {
        path: "/email-verification",
        query: { redirect: to.fullPath }
    };

    if(process.env.VUE_APP_AUTHENTICATE === 'false'){
        next();
    }

    if (!store.getters["auth/authUser"]) {
        store.dispatch("auth/getAuthUser").then(() => {
            if (!store.getters["auth/authUser"]) {
                let error = store.getters["auth/responseError"];
                if(error.response.status === 403 && error.response.data.message === "Your email address is not verified."){
                    if (router.currentRoute.name !== "email-verification") {
                        next(emailVerificationQuery);
                    }
                }
                next(loginQuery);
            } else {
                next();
            }
        });
    } else {
        next();
    }
}