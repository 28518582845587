import { getError, fileDownload } from "@/utils/helpers";
import ReportingService from "@/services/ReportingService";

export const namespaced = true;


export const state = () => ({
    records: [],
    error: null,
    roles: [],
    roleBreakdown: null,
    instances: [],
    instanceBreakdown: null,
    global: null,
    globaActive: null,
    globalTimeSeries: [],
    globaLRoles: [],
    globalRoleRankings: [],

    pagination: {},

    browseRecordStatus: 0,
    rolesStatus: 0,
    rolesExportStatus: 0,
    roleBreakdownStatus: 0,
    instancesStatus: 0,
    instancesExportStatus: 0,
    instanceBreakdownStatus: 0,
    globalStatus: 0,
    globaActiveStatus: 0,
    globalTimeSeriesStatus: 0,
    globaLRolesStatus: 0,
    globalRoleRankingsStatus: 0,


    });

export const mutations = {
    SET_RECORDS(state, records) {
        state.records = records;
    },

    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_BROWSE_RECORD_STATUS(state, status) {
        state.browseRecordStatus = status;
    },

    SET_ROLES(state, roles) {
        state.roles = roles;
    },
    SET_ROLE_BREAKDOWN(state, roleBreakdown) {
        state.roleBreakdown = roleBreakdown;
    },
    SET_INSTANCES(state, instances) {
        state.instances = instances;
    },
    SET_INSTANCE_BREAKDOWN(state, instanceBreakdown) {
        state.instanceBreakdown = instanceBreakdown;
    },
    SET_GLOBAL(state, global) {
        state.global = global;
    },
    SET_GLOBAL_ACTIVE(state, globalActive) {
        state.globaActive = globalActive;
    },
    SET_GLOBAL_TIME_SERIES(state, globalTimeSeries) {
        state.globalTimeSeries = globalTimeSeries;
    },
    SET_GLOBAL_ROLES(state, globalRoles) {
        state.globaLRoles = globalRoles;
    },
    SET_GLOBAL_ROLE_RANKINGS(state, globalRoleRankings) {
        state.globalRoleRankings = globalRoleRankings;
    },
    SET_PAGINATION(state, pagination) {
        state.pagination = pagination;
    },
    SET_ROLES_STATUS(state, status) {
        state.rolesStatus = status;
    },
    SET_ROLES_EXPORT_STATUS(state, status) {
        state.rolesExportStatus = status;
    },
    SET_ROLE_BREAKDOWN_STATUS(state, status) {
        state.roleBreakdownStatus = status;
    },
    SET_INSTANCES_STATUS(state, status) {
        state.instancesStatus = status;
    },
    SET_INSTANCES_EXPORT_STATUS(state, status) {
        state.instancesExportStatus = status;
    },
    SET_INSTANCE_BREAKDOWN_STATUS(state, status) {
        state.instanceBreakdownStatus = status;
    },
    SET_GLOBAL_STATUS(state, status) {
        state.globalStatus = status;
    },
    SET_GLOBAL_ACTIVE_STATUS(state, status) {
        state.globaActiveStatus = status;
    },
    SET_GLOBAL_TIME_SERIES_STATUS(state, status) {
        state.globalTimeSeriesStatus = status;
    },
    SET_GLOBAL_ROLES_STATUS(state, status) {
        state.globaLRolesStatus = status;
    },
    SET_GLOBAL_ROLE_RANKINGS_STATUS(state, status) {
        state.globalRoleRankingsStatus = status;
    },

};

export const getters = {
    records: (state) => { return state.records; },
    error: (state) => { return state.error; },
    roles: (state) => { return state.roles; },
    roleBreakdown: (state) => { return state.roleBreakdown; },
    instances: (state) => { return state.instances; },
    instanceBreakdown: (state) => { return state.instanceBreakdown; },
    global: (state) => { return state.global; },
    globaActive: (state) => { return state.globaActive; },
    globalTimeSeries: (state) => { return state.globalTimeSeries; },
    globaLRoles: (state) => { return state.globaLRoles; },
    globalRoleRankings: (state) => { return state.globalRoleRankings; },
    pagination: (state) => { return state.pagination; },
    browseRecordStatus: (state) => { return state.browseRecordStatus; },
    rolesStatus: (state) => { return state.rolesStatus; },
    rolesExportStatus: (state) => { return state.rolesExportStatus; },
    roleBreakdownStatus: (state) => { return state.roleBreakdownStatus; },
    instancesStatus: (state) => { return state.instancesStatus; },
    instancesExportStatus: (state) => { return state.instancesExportStatus; },
    instanceBreakdownStatus: (state) => { return state.instanceBreakdownStatus; },
    globalStatus: (state) => { return state.globalStatus; },
    globaActiveStatus: (state) => { return state.globaActiveStatus; },
    globalTimeSeriesStatus: (state) => { return state.globalTimeSeriesStatus; },
    globaLRolesStatus: (state) => { return state.globaLRolesStatus; },
    globalRoleRankingsStatus: (state) => { return state.globalRoleRankingsStatus; },
};

export const actions = {
    getRolesStatistics({commit}, filters) {
        commit("SET_ROLES_STATUS", 1);
        commit("SET_ERROR", null);
        ReportingService.getRoles(filters).then((response) => {
            commit("SET_ROLES", response.data.data);
            delete response.data.meta.links;
            commit("SET_PAGINATION", response.data.meta);
            commit("SET_ROLES_STATUS", 2);
        }).catch((error) => {
            commit("SET_ROLES", []);
            commit("SET_PAGINATION", null);
            commit("SET_ERROR", getError(error));
            commit("SET_ROLES_STATUS", 3);
        });
    },

    getRolesStatisticsExport({commit}, filters) {
        commit("SET_ROLES_EXPORT_STATUS", 1);
        commit("SET_ERROR", null);
        ReportingService.getRoles(filters).then((response) => {
            let contentDispositionHeader = response.headers['content-disposition'];
            let filename = contentDispositionHeader.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
            fileDownload(response.data, filename);
            commit("SET_ROLES_EXPORT_STATUS", 2);
        }).catch((error) => {
            commit("SET_ERROR", getError(error));
            commit("SET_ROLES_EXPORT_STATUS", 3);
        });
    },

    initRoleBreakdown({commit}){
        commit("SET_ROLE_BREAKDOWN_STATUS", 0);
        commit("SET_ROLE_BREAKDOWN", null);
    },
    getRoleBreakdown({commit}, filters) {
        commit("SET_ROLE_BREAKDOWN_STATUS", 1);
        commit("SET_ERROR", null);
        ReportingService.getRoleBreakdown(filters).then((response) => {
            commit("SET_ROLE_BREAKDOWN", response.data);
            commit("SET_ROLE_BREAKDOWN_STATUS", 2);
        }).catch((error) => {
            commit("SET_ROLE_BREAKDOWN", null);
            commit("SET_ERROR", getError(error));
            commit("SET_ROLE_BREAKDOWN_STATUS", 3);
        });
    },

    getInstancesStatistics({commit}, filters) {
        commit("SET_INSTANCES_STATUS", 1);
        commit("SET_ERROR", null);
        ReportingService.getInstances(filters).then((response) => {
            commit("SET_INSTANCES", response.data.data);
            delete response.data.meta.links;
            commit("SET_PAGINATION", response.data.meta);
            commit("SET_INSTANCES_STATUS", 2);
        }).catch((error) => {
            commit("SET_ROLES", []);
            commit("SET_PAGINATION", null);
            commit("SET_ERROR", getError(error));
            commit("SET_ROLES_STATUS", 3);
        });
    },

    getInstancesStatisticsExport({commit}, filters) {
        commit("SET_INSTANCES_EXPORT_STATUS", 1);
        commit("SET_ERROR", null);
        ReportingService.getInstances(filters).then((response) => {
            let contentDispositionHeader = response.headers['content-disposition'];
            let filename = contentDispositionHeader.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
            fileDownload(response.data, filename);
            commit("SET_INSTANCES_EXPORT_STATUS", 2);
        }).catch((error) => {
            commit("SET_ERROR", getError(error));
            commit("SET_INSTANCES_EXPORT_STATUS", 3);
        });
    },

    initInstanceBreakdown({commit}){
        commit("SET_INSTANCE_BREAKDOWN_STATUS", 0);
        commit("SET_INSTANCE_BREAKDOWN", null);
    },
    getInstanceBreakdown({commit}, filters) {
        commit("SET_INSTANCE_BREAKDOWN_STATUS", 1);
        commit("SET_ERROR", null);
        ReportingService.getInstanceBreakdown(filters).then((response) => {
            commit("SET_INSTANCE_BREAKDOWN", response.data);
            commit("SET_INSTANCE_BREAKDOWN_STATUS", 2);
        }).catch((error) => {
            commit("SET_INSTANCE_BREAKDOWN", null);
            commit("SET_ERROR", getError(error));
            commit("SET_INSTANCE_BREAKDOWN_STATUS", 3);
        });
    },

    initGlobalStats({commit}){
        commit("SET_GLOBAL_STATUS", 0);
        commit("SET_GLOBAL", null);
        commit("SET_GLOBAL_ACTIVE_STATUS", 0);
        commit("SET_GLOBAL_ACTIVE", null);
        commit("SET_GLOBAL_TIMESERIES_STATUS", 0);
        commit("SET_GLOBAL_TIMESERIES", null);
        commit("SET_GLOBAL_ROLES_STATUS", 0);
        commit("SET_GLOBAL_ROLES", null);
        commit("SET_GLOBAL_ROLE_RANKINGS_STATUS", 0);
        commit("SET_GLOBAL_ROLE_RANKINGS", null);
    },
    getGlobalStatistics({commit}, filters) {
        commit("SET_GLOBAL_STATUS", 1);
        commit("SET_ERROR", null);
        ReportingService.getGlobalStatistics(filters).then((response) => {
            commit("SET_GLOBAL", response.data);
            commit("SET_GLOBAL_STATUS", 2);
        }).catch((error) => {
            commit("SET_GLOBAL", []);
            commit("SET_ERROR", getError(error));
            commit("SET_GLOBAL_STATUS", 3);
        });
    },

    getGlobalActiveStatistics({commit}, filters) {
        commit("SET_GLOBAL_ACTIVE_STATUS", 1);
        commit("SET_ERROR", null);
        ReportingService.getGlobalActiveStatistics(filters).then((response) => {
            commit("SET_GLOBAL_ACTIVE", response.data);
            commit("SET_GLOBAL_ACTIVE_STATUS", 2);
        }).catch((error) => {
            commit("SET_GLOBAL_ACTIVE", []);
            commit("SET_ERROR", getError(error));
            commit("SET_GLOBAL_ACTIVE_STATUS", 3);
        });
    },

    getGlobalTimeSeries({commit}, filters) {
        commit("SET_GLOBAL_TIME_SERIES_STATUS", 1);
        commit("SET_ERROR", null);
        ReportingService.getGlobalTimeSeries(filters).then((response) => {
            commit("SET_GLOBAL_TIME_SERIES", response.data);
            commit("SET_GLOBAL_TIME_SERIES_STATUS", 2);
        }).catch((error) => {
            commit("SET_GLOBAL_TIME_SERIES", []);
            commit("SET_ERROR", getError(error));
            commit("SET_GLOBAL_TIME_SERIES_STATUS", 3);
        });
    },

    getGlobalRoles({commit}, filters) {
        commit("SET_GLOBAL_ROLES_STATUS", 1);
        commit("SET_ERROR", null);
        ReportingService.getGlobalRoles(filters).then((response) => {
            commit("SET_GLOBAL_ROLES", response.data);
            commit("SET_GLOBAL_ROLES_STATUS", 2);
        }).catch((error) => {
            commit("SET_GLOBAL_ROLES", []);
            commit("SET_ERROR", getError(error));
            commit("SET_GLOBAL_ROLES_STATUS", 3);
        });
    },

    getGlobalRoleRankings({commit}, filters) {
        commit("SET_GLOBAL_ROLE_RANKINGS_STATUS", 1);
        commit("SET_ERROR", null);
        ReportingService.getGlobalRoleRankings(filters).then((response) => {
            commit("SET_GLOBAL_ROLE_RANKINGS", response.data);
            commit("SET_GLOBAL_ROLE_RANKINGS_STATUS", 2);
        }).catch((error) => {
            commit("SET_GLOBAL_ROLE_RANKINGS", []);
            commit("SET_ERROR", getError(error));
            commit("SET_GLOBAL_ROLE_RANKINGS_STATUS", 3);
        });
    },

};