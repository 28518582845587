import {getError} from "../../utils/helpers";
import AccountSettingsService from "../../services/AccountSettingsService";

export const namespaced = true;


export const state = () => ({

    error: null,
    resetPasswordStatus: 0,
    changeEmailStatus: 0,
    updateAccountDetailsStatus: 0,
    updateAccountSettingsStatus: 0,
    fetchAccountDetailsStatus: 0,
    fetchAccountSettingsStatus: 0,

    accountSettings: null,
    accountDetails: null
});


export const mutations = {
    SET_ERROR(state, error){
        state.error = error;
    },

    SET_RESET_PASSWORD_STATUS(state, status){
        state.resetPasswordStatus = status ;
    },
    SET_CHANGE_EMAIL_STATUS(state, status){
        state.changeEmailStatus = status;
    },
    SET_UPDATE_ACCOUNT_DETAILS_STATUS(state, status){
        state.updateAccountDetailsStatus = status;
    },
    SET_UPDATE_ACCOUNT_SETTINGS_STATUS(state, status){
        state.updateAccountSettingsStatus = status;
    },
    SET_FETCH_ACCOUNT_DETAILS_STATUS(state, status){
        state.fetchAccountDetailsStatus = status;
    },
    SET_FETCH_ACCOUNT_SETTINGS_STATUS(state, status){
        state.fetchAccountSettingsStatus = status;
    },
    SET_ACCOUNT_SETTINGS(state, settings){
        state.accountSettings = settings;
    },
    SET_ACCOUNT_DETAILS(state, details){
        state.accountDetails = details;
    },
}

export const getters = {
    error: (state) => {return state.error},
    resetPasswordStatus: (state) => {return state.resetPasswordStatus},
    changeEmailStatus: (state) => {return state.changeEmailStatus},
    updateAccountDetailsStatus: (state) => {return state.updateAccountDetailsStatus},
    updateAccountSettingsStatus: (state) => {return state.updateAccountSettingsStatus},
    fetchAccountDetailsStatus: (state) => {return state.fetchAccountDetailsStatus},
    fetchAccountSettingsStatus: (state) => {return state.fetchAccountSettingsStatus},
    accountSettings: (state) => {return state.accountSettings},
    accountDetails: (state) => {return state.accountDetails},
}

export const actions = {
    changePassword({commit}, payload) {
        commit('SET_ERROR', null);
        commit("SET_RESET_PASSWORD_STATUS", 1);
        AccountSettingsService.changePassword(payload).then(() => {
            commit("SET_RESET_PASSWORD_STATUS", 2);
        }).catch((error) => {
            commit('SET_ERROR', getError(error));
            commit("SET_RESET_PASSWORD_STATUS", 3);
        });
    },

    changeEmail({commit}, payload) {
        commit('SET_ERROR', null);
        commit("SET_CHANGE_EMAIL_STATUS", 1);
        AccountSettingsService.changeEmail(payload).then(() => {
            commit("SET_CHANGE_EMAIL_STATUS", 2);
        }).catch((error) => {
            commit('SET_ERROR', getError(error));
            commit("SET_CHANGE_EMAIL_STATUS", 3);
        });
    },

    fetchAccountDetails({commit}, payload) {
        commit('SET_ERROR', null);
        commit("SET_FETCH_ACCOUNT_DETAILS_STATUS", 1);
        AccountSettingsService.accountDetails(payload).then((response) => {
            commit("SET_ACCOUNT_DETAILS", response.data.data);
            commit("SET_FETCH_ACCOUNT_DETAILS_STATUS", 2);
        }).catch((error) => {
            commit('SET_ERROR', getError(error));
            commit("SET_ACCOUNT_DETAILS", null);
            commit("SET_FETCH_ACCOUNT_DETAILS_STATUS", 3);
        });
    },

    updateAccountDetails({commit}, payload) {
        commit('SET_ERROR', null);
        commit("SET_UPDATE_ACCOUNT_DETAILS_STATUS", 1);
        AccountSettingsService.updateAccountDetails(payload).then(() => {
            commit("SET_UPDATE_ACCOUNT_DETAILS_STATUS", 2);
        }).catch((error) => {
            commit('SET_ERROR', getError(error));
            commit("SET_UPDATE_ACCOUNT_DETAILS_STATUS", 3);
        });
    },
}