import * as API from "@/services/API";

const baseUrl = '/v1/calls';


export default {
    getCall(id) {
        return API.apiClient.get(`${baseUrl}/${id}`);
    },
    getCalls(filters) {
        return API.apiClient.get(baseUrl, {params: filters});
    },
    getCallRecordings(id) {
        return API.apiClient.get(`${baseUrl}/${id}/recordings`);
    },

    getCallData(id) {
        return API.apiClient.get(`${baseUrl}/${id}/data`);
    },

    getCallLog(id) {
        return API.apiClient.get(`${baseUrl}/${id}/log`);
    },

    getCallActions(id) {
        return API.apiClient.get(`${baseUrl}/${id}/actions`);
    },

    getCallTranscript(id) {
        return API.apiClient.get(`${baseUrl}/${id}/transcript`);
    }
}