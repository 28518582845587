import axios from "axios";
import store from "@/store";
import * as API from "@/services/API";

export const authClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,  // required to handle the CSRF token
});

/**
 * Add a response interceptor
 */
authClient.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        if (
            error.response && [401, 419].includes(error.response.status) && store.getters["auth/authUser"] && !store.getters["auth/guest"]
        ) {
            store.dispatch("auth/logout");
        }
        return Promise.reject(error);
    }
);

export default {
    async login(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/login", payload);
    },

    logout() {
        return authClient.post("/logout");
    },

    async forgotPassword(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/forgot-password", payload);
    },

    getAuthUser() {
        return authClient.get("/api/v1/users/auth");
    },

    async resetPassword(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/reset-password", payload);
    },

    async resendVerifyEmail(email) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/api/v1/email/resend", {
            email: email
        });
    },

    updatePassword(payload) {
        return authClient.put("/user/password", payload);
    },

    async registerUser(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/register", payload);
    },

    sendVerification(payload) {
        return authClient.post("/email/verification-notification", payload);
    },

    updateUser(payload) {
        return authClient.put("/user/profile-information", payload);
    },

    getUserPermissions() {
        return API.apiClient.get("/v1/user/permissions");
    },

    verifyEmail(url) {
        return authClient.get(url);
    },

    changeUserEmail(payload) {
        return authClient.post("/api/v1/user/change-email", payload);
    },

    changeUserPassword(payload) {
        return authClient.post("/api/v1/user/change-password", payload);
    },

    updateAccountSettings(payload) {
        return authClient.post("/api/v1/user/settings", payload);
    },

    readAccountSettings(payload) {
        return authClient.get("/api/v1/user/settings", payload);
    }
};
