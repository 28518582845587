import guest from "../../middleware/guest";

export default [
    {
        path: '/',
        name: 'home',
        meta: {
            middleware: [ guest ]
        },
        component: () => import("../../views/Core/Landing")
    },
    {
        path: '/contact',
        name: 'contact',
        meta: {
            middleware: [ guest ]
        },
        component: () => import("../../views/Core/ContactUs")
    },
    {
        path: '/faq',
        name: 'faq',
        meta: {
            middleware: [ guest ]
        },
        component: () => import("../../views/Core/Faq")
    },
];