import axios from "axios";
import store from "@/store";

export const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/api",
    withCredentials: true
});

//The account filter middleware/interceptop.
apiClient.interceptors.request.use((config) => {
    console.log(config);

    let account_id = store.getters["Account/globalAccountFilter"];

    if(account_id !== null && account_id !== undefined){
        if(config.method === 'get') {
            config.params = config.params || {};
            config.params['account_id'] = account_id;
        }
        if(config.method === 'post'){
            if(Object.prototype.hasOwnProperty.call(config,'data')){
                config.data['account_id'] = account_id;
            }
        }
    }


    return config;
});

apiClient.interceptors.response.use(
    (response) => {
      return response;
    },

    function (error) {
        if (
            error.response && [401, 419].includes(error.response.status) && store.getters["auth/authUser"] && !store.getters["auth/guest"]
        ) {
            store.dispatch("auth/logout");
        }
        return Promise.reject(error);
    }
);