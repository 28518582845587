import {fileDownload, getError} from "@/utils/helpers";
import CallService from "@/services/CallService";

export const namespaced = true;


export const state = () => ({
    records: [],
    record: null,
    meta: null,
    links: null,
    loading: false,
    error: null,
    pagination: {
        total: 0
    },
    recordings: [],
    data: [],
    log:[],
    actions: [],
    transcript: null,

    browseRecordStatus: 0,
    recordsExportStatus: 0,
    readRecordStatus: 0,
    getRecordingsStatus: 0,
    getDataStatus: 0,
    getLogStatus: 0,
    getActionsStatus: 0,
    getTranscriptStatus: 0,
    });

export const mutations = {
    SET_RECORDS(state, records) {
        state.records = records;
    },

    SET_RECORD(state, record) {
        state.record = record;
    },

    SET_META(state, meta) {
        state.meta = meta;
    },

    SET_LINKS(state, links) {
        state.links = links;
    },

    SET_LOADING(state, loading) {
        state.loading = loading;
    },

    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_BROWSE_RECORD_STATUS(state, status) {
        state.browseRecordStatus = status;
    },

    SET_RECORDS_EXPORT_STATUS(state, status){
        state.recordsExportStatus = status;
    },

    SET_READ_RECORD_STATUS(state, status) {
        state.readRecordStatus = status;
    },
    SET_PAGINATION(state, pagination){
        state.pagination = pagination;
    },
    SET_RECORDINGS(state, recordings){
        state.recordings = recordings;
    },
    SET_DATA(state, data){
        state.data = data;
    },
    SET_LOG(state, log){
        state.log = log;
    },
    SET_ACTIONS(state, actions){
        state.actions = actions;
    },
    SET_TRANSCRIPT(state, transcript){
        state.transcript = transcript;
    },
    SET_GET_RECORDINGS_STATUS(state, status){
        state.getRecordingsStatus = status;
    },
    SET_GET_DATA_STATUS(state, status){
        state.getDataStatus = status;
    },
    SET_GET_LOG_STATUS(state, status){
        state.getLogStatus = status;
    },
    SET_GET_ACTIONS_STATUS(state, status){
        state.getActionsStatus = status;
    },
    SET_GET_TRANSCRIPT_STATUS(state, status){
        state.getTranscriptStatus = status;
    },
};

export const getters = {
    records: (state) => {
        return state.records;
    },

    record: (state) => {
        return state.record;
    },

    meta: (state) => {
        return state.meta;
    },

    links: (state) => {
        return state.links;
    },

    loading: (state) => {
        return state.loading;
    },

    error: (state) => {
        return state.error;
    },

    browseRecordStatus: (state) => {
        return state.browseRecordStatus;
    },
    recordsExportStatus: (state) => {
        return state.recordsExportStatus;
    },

    readRecordStatus: (state) => {
        return state.readRecordStatus;
    },

    pagination: (state) => {
        return state.pagination;
    },

    paginateRecordStatus: (state) => {
        return state.paginateRecordStatus;
    },
    recordings: (state) => { return state.recordings;},
    data: (state) => { return state.data;},
    log: (state) => { return state.log;},
    actions: (state) => { return state.actions;},
    transcript: (state) => { return state.transcript;},
    getRecordingsStatus: (state) => { return state.getRecordingsStatus;},
    getDataStatus: (state) => { return state.getDataStatus;},
    getLogStatus: (state) => { return state.getLogStatus;},
    getActionsStatus: (state) => { return state.getActionsStatus;},
    getTranscriptStatus: (state) => { return state.getTranscriptStatus;},
};

export const actions = {
    browseRecords({commit}, filters) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_BROWSE_RECORD_STATUS", 1);
        CallService.getCalls(filters).then((response) => {
            commit("SET_RECORDS", response.data.data);
            delete response.data.meta.links;
            commit("SET_PAGINATION", response.data.meta);
            commit("SET_BROWSE_RECORD_STATUS", 2);
        }).catch((error) => {
            commit("SET_LOADING", false);
            commit("SET_BROWSE_RECORD_STATUS", 3);
            commit("SET_ERROR", getError(error));
        });
    },

    browseRecordsExport({commit}, filters) {
        commit("SET_ERROR", null);
        commit("SET_RECORDS_EXPORT_STATUS", 1);
        CallService.getCalls(filters).then((response) => {
            let contentDispositionHeader = response.headers['content-disposition'];
            let filename = contentDispositionHeader.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
            fileDownload(response.data, filename);
            commit("SET_RECORDS_EXPORT_STATUS", 2);
        }).catch((error) => {
            commit("SET_RECORDS_EXPORT_STATUS", 3);
            commit("SET_ERROR", getError(error));
        });
    },
    readRecord({commit}, recordId) {
        commit("SET_ERROR", null);
        commit("SET_READ_RECORD_STATUS", 1);
        CallService.getCall(recordId).then((response) => {
            commit("SET_READ_RECORD_STATUS", 2);
            commit("SET_RECORD", response.data.data);
        }).catch((error) => {
            commit("SET_READ_RECORD_STATUS", 3);
            commit("SET_RECORD", null);
            commit("SET_ERROR", getError(error));
        });
    },
    getCallRecordings({commit}, recordId) {
        commit("SET_GET_RECORDINGS_STATUS", 1);
        commit("SET_RECORDINGS", null);
        commit("SET_ERROR", null);
        CallService.getCallRecordings(recordId).then((response) => {
            commit("SET_GET_RECORDINGS_STATUS", 2);
            commit("SET_RECORDINGS", response.data.data);
        }).catch((error) => {
            commit("SET_GET_RECORDINGS_STATUS", 3);
            commit("SET_RECORDINGS", null);
            commit("SET_ERROR", getError(error));
        });
    },
    getCallData({commit}, recordId) {
        commit("SET_GET_DATA_STATUS", 1);
        commit("SET_DATA", null);
        commit("SET_ERROR", null);
        CallService.getCallData(recordId).then((response) => {
            commit("SET_GET_DATA_STATUS", 2);
            commit("SET_DATA", response.data.data);
        }).catch((error) => {
            commit("SET_GET_DATA_STATUS", 3);
            commit("SET_DATA", null);
            commit("SET_ERROR", getError(error));
        });
    },

    getCallLog({commit}, recordId) {
        commit("SET_GET_LOG_STATUS", 1);
        commit("SET_LOG", null);
        commit("SET_ERROR", null);
        CallService.getCallLog(recordId).then((response) => {
            commit("SET_GET_LOG_STATUS", 2);
            commit("SET_LOG", response.data.data);
        }).catch((error) => {
            commit("SET_GET_LOG_STATUS", 3);
            commit("SET_LOG", null);
            commit("SET_ERROR", getError(error));
        });
    },
    getCallActions({commit}, recordId) {
        commit("SET_GET_ACTIONS_STATUS", 1);
        commit("SET_ACTIONS", null);
        commit("SET_ERROR", null);
        CallService.getCallActions(recordId).then((response) => {
            commit("SET_GET_ACTIONS_STATUS", 2);
            commit("SET_ACTIONS", response.data.data);
        }).catch((error) => {
            commit("SET_GET_ACTIONS_STATUS", 3);
            commit("SET_ACTIONS", null);
            commit("SET_ERROR", getError(error));
        });
    },
    getCallTranscript({commit}, recordId) {
        commit("SET_GET_TRANSCRIPT_STATUS", 1);
        commit("SET_TRANSCRIPT", null);
        commit("SET_ERROR", null);
        CallService.getCallTranscript(recordId).then((response) => {
            commit("SET_GET_TRANSCRIPT_STATUS", 2);
            commit("SET_TRANSCRIPT", response.data);
        }).catch((error) => {
            commit("SET_GET_TRANSCRIPT_STATUS", 3);
            commit("SET_TRANSCRIPT", null);
            commit("SET_ERROR", getError(error));
        });
    },
};