export const getError = (error) => {
    const errorMessage = "API Error, please try again.";

    if (error.name === "Fetch User") {
        return error.message;
    }

    if (!error.response) {
        console.log(error);
        //console.error(`API ${error.config.url} not found`);
        return  errorMessage;
    }

    if (process.env.NODE_ENV === "development") {
        console.log(error);
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
    }

    if (error.response.data && error.response.data.errors) {
        return error.response.data.errors;
    }

    return errorMessage;
};

export const fullName = (first_name, last_name) => {
    return first_name + ' ' + last_name;
};

export const logoPath = "/layout/images/logo.svg";
export const logoPathContrasted = "/layout/images/logo.svg";
export const fileDownload = require('js-file-download');

export const getInitials = (first_name, last_name) => {
    return first_name.charAt(0).toUpperCase() + last_name.charAt(0).toUpperCase();
};

export const toastTypes = {
    success: 'success',
    error: 'error',
    info: 'info',
    warn: 'warn'
};

export const APP_HELPER = {
    logoPath: "/layout/images/logo.svg",
    logoPathContrasted: "/layout/images/logo.svg",
    fullName: function (first_name, last_name) {
        return first_name + ' ' + last_name;
    },
    getInitials: function(first_name, last_name) {
        return first_name.charAt(0).toUpperCase() + last_name.charAt(0).toUpperCase();
    },
    toastTypes: {
        success: 'success',
        error: 'error',
        info: 'info',
        warn: 'warn'
    },
    getError: function(error) {
        const errorMessage = "API Error, please try again.";

        if (error.name === "Fetch User") {
            return error.message;
        }

        if (!error.response) {
            console.log(error);
            console.error(`API ${error.config.url} not found`);
            return  errorMessage;
        }

        if (process.env.NODE_ENV === "development") {
            console.log(error);
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        }

        if (error.response.data && error.response.data.errors) {
            return error.response.data.errors;
        }

        return errorMessage;
    },
    isEmpty: function(value){
        return !(value !== null && value !== undefined && value !== '');
    },
    jsUcfirst: function(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatPhoneNumber: function (value) {
        let cleaned = ('' + value).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + ' ('+ match[2] +')' + '-' + match[3]+ '-' + match[4];
        }
        //console.log(phoneNumberString);
        return null;
    },
    onlyNumbers: function(evt){
        //console.log(evt);
        if( !/\d/.test(evt.key) && evt.key === '.' || evt.key === 'e') return evt.preventDefault();
    },
    debounce: function (func, timeout = 300) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    },
    breadModels: {
        Account: 'Account',
        User: 'User',
        Action: 'Action',
        CallData: 'Call-Data',
        Instance: 'Instance',
        Recording: 'Recording',
        Role: 'Role',
        SipCreds: 'Sip-Credential',
        Transcript: 'Transcript',
    },
};

export const AUTH_HELPER = {
    canUser: function (permissions, action, model, level = null) {
        let can = false;
        console.log("AUTH_HELPER.canUser");
        console.log(action + ' - ' + model);
        console.log(permissions);
        if (Object.prototype.hasOwnProperty.call(permissions[model], action)) {
            if (permissions[model][action]) {
                can = true;
                if (level !== null) {
                    can = (can && permissions[model]['level'] >= level);
                }
            }
        }
        return can;
    },

    superAdmin: function (user, model) {
        let can = false;
        if(Object.prototype.hasOwnProperty.call(user, model) && user[model] === 9) {
            can = true;
        }
        return can;
    },

    regularAdmin: function (user, model) {
        let can = false;
        if (Object.prototype.hasOwnProperty.call(user, model) && user[model] === 8) {
            can = true;
        }
        return can;
    },
    authActions: {
        browse: 'browse',
        read: 'read',
        edit: 'edit',
        add: 'add',
        delete: 'delete'
    },
    authModels: {
        Account: 'Accounts',
        User: 'Users',
        Action: 'Actions',
        CallData: 'Call-Data',
        Instance: 'Instances',
        Recording: 'Recordings',
        Role: 'Roles',
        SipCreds: 'Sip-Credentials',
        Transcript: 'Transcripts',
        Reporting: 'Reporting',
    },
}