import ModelView from "../../views/ModelView";
import {AUTH_HELPER} from "../../utils/helpers";

export default [
    {
        path: '/roles',
        name: 'roles',
        redirect: '/roles/',
        meta: {
            requirePermission: true,
            model: AUTH_HELPER.authModels.Role
        },
        component: ModelView,
        children: [
            {
                path: '',
                meta: {
                    action: AUTH_HELPER.authActions.browse,
                    breadcrumb: [
                        {
                            label: 'roles',
                        }
                    ]
                },
                component: () => import('../../views/Roles/Index')
            },
            {
                path: "/roles/new",
                name: "newRole",
                meta: {
                    action: AUTH_HELPER.authActions.add,
                    breadcrumb: [
                        {
                            parent: 'roles',
                            label: 'new',
                        }
                    ]
                },
                component: () => import('../../views/Roles/RoleNew'),
            },
            {
                path: '/roles/:roleId',
                name: 'role',
                meta: {
                    action: AUTH_HELPER.authActions.read,
                    breadcrumb: [
                        {
                            parent: 'roles',
                            label: 'show',
                        }
                    ]
                },
                component: () => import('../../views/Roles/Role')
            },
            {
                path: "/roles/:roleId/edit",
                name: "editRole",
                component: () => import('../../views/Roles/RoleEdit'),
                meta: {
                    action: AUTH_HELPER.authActions.edit,
                    breadcrumb: [
                        {
                            parent: 'roles',
                            label: 'edit',
                        }
                    ]
                },
            },
        ]
    }
];